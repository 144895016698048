<!-- 总控制台 -->
<template>
  <div class="Console">
    <Spin size="large" fix v-if="spinShow"></Spin>
    <!-- 已完善资料 -->
    <div v-if="firm_data.licenseImage">
      <header class="inp">
        <Button type="warning" size="large" @click="search">搜索</Button>
        <Input v-model="name" @on-enter="search" size="large" prefix="ios-search" placeholder="输入关键词，搜索相关人才">
        </Input>
      </header>

      <section>
        <Row>

          <Col span="11">
          <div class="title">
            <img src="@/assets/images/index_i_1.png" alt="">
            <p>我的简历</p>
          </div>
          <div class="content">

            <Row>
              <Col span="12">
              <span @click="go('Management','简历管理')">待查看简历</span>
              <br>
              <span @click="go('Management','简历管理')">{{page_data.resumeCount}}</span>
              </Col>
              <Col span="12">
              <span @click="go('Management','简历管理')">收藏的简历</span>
              <br>
              <span @click="go('Management','简历管理')">{{page_data.collectCount}}</span>
              </Col>
            </Row>

          </div>
          </Col>
          <Col span="1">
          </Col>

          <Col span="12">
          <div class="title">
            <img src="@/assets/images/index_i_2.png" alt="">
            <p>我的简历</p>
          </div>
          <div class="content_tow">
            <Row>
              <Col span="12">
              <div class="content_tow_in">
                <span>全职·在招职位</span>
                <span style="display:flex;justify-content: center;align-items:center">

                  <img @click="go('job_manage','职位管理',1)" src="@/assets/images/index_fabu.png" alt="">
                  <span @click="go('job_manage','职位管理',1)">发布</span>
                </span>
              </div>
              <span class="number" @click="go('job_manage','职位管理',1)">{{page_data.positionTypeOne}}</span>
              </Col>
              <Col span="12">

              <div class="content_tow_in">
                <span>小时工·在招职位</span>
                <span style="display:flex;justify-content: center;align-items:center">
                  <img @click="go('job_manage','职位管理',2)" src="@/assets/images/index_fabu.png" alt="">
                  <span @click="go('job_manage','职位管理',2)">发布</span>
                </span>
              </div>
              <span class="number" @click="go('job_manage','职位管理',2)">{{page_data.positionTypeTow }}</span>
              </Col>
            </Row>
          </div>
          </Col>

        </Row>
      </section>

      <article>

        <Row>
          <Col span="24">
          <div class="title">
            <img src="@/assets/images/index_i_3.png" alt="">
            <p>我的权益</p>
          </div>
          <div class="content">
            <span @click="go('talents_manage','人才管理')">已查看人才数</span>
            <br>
            <span @click="go('talents_manage','人才管理')">{{talents}}</span>

          </div>
          </Col>

        </Row>
      </article>
    </div>

    <!-- 未完善资料 -->
    <div class="noCompany" v-else>
      <img src="@/assets/images/noCompany.png" alt="">
      <p>您还未完善企业信息，完善后可发布岗位！</p>
      <!-- <Button type="warning" size="large" @click="go('firm_data','企业资料')">完善企业信息</Button> -->
      <Button type="warning" size="large" @click="company_detail(enterpriseId)">完善企业信息</Button>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      talents: "",
      //页面数据
      page_data: {},
      spinShow: false
    }
  },
  methods: {
    company_detail() {
      this.$router.push({ name: 'firm_data_detail', query: { id: localStorage.getItem("firm_enterpriseId"), change_company: 1 } })
      this.$emit("setTitle", "企业资料")
    },
    //完善企业信息
    go(router, menu, type) {
      this.$router.push({ name: router, query: { id: type } })
      this.$emit("setTitle", menu)
    },
    search() {
      this.$router.push({ name: 'talents_list', params: { name: this.name } })
      this.$emit("setTitle", "人才列表")
    },
    //封装获取数据
    get_data() {
      this.spinShow = true
      this.app('enterpriseOperators', 'myResumeCollection', {
        mobile: localStorage.getItem('firm_mobile')
      }).then(res => {
        console.log(res);
        this.page_data = res.data
        this.spinShow = false
      })
    },
    //封装获取已查看人才数
    get_talents() {
      this.app('enterpriseOperators', 'getDownload', {
        mobile: localStorage.getItem('firm_mobile')
      }).then(res => {
        // console.log(res);
        this.talents = res.data
      })

    }
  },
  props: ['firm_data'],

  created() {
    this.get_data()
    this.get_talents()

  },
  updated() {
    console.log(this.firm_data);
    if (!this.firm_data.licenseImage) {
      this.$Modal.confirm({
        title: '提示',
        content: '企业信息未完善',
        closable: true,
        onOk: () => {
          // this.$router.push("firm_data")
          // this.$emit("setTitle", "企业资料")
          this.$router.push({ name: 'firm_data_detail', query: { id: localStorage.getItem("firm_enterpriseId"), change_company: 1 } })
          this.$emit("setTitle", "企业资料")
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./Console.less";
</style>
